@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container{
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 1rem;
}

.heading{
  font-size: 2rem;
  font-weight: 800;
}
@media(min-width: 1374px){
  .heading {
      font-size: 4rem;
    }
}
@media (min-width: 1083px) {
  .heading {
    font-size: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 1084px) {
  .container {
    width: 95% !important;
    padding: 0px;
  }
  .heading {
    font-size: 2.5rem;
  }
}
